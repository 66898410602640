<template>
  <div class="row">
    <ConfirmModal
      ref="modal_delete_schedule"
      :modal-header-title="$t('common.delete confirm title')"
      :confirm-handle="deleteEntrySubmit"
      :on-hide="clearEntryToSave"
    ></ConfirmModal>
    <template v-for="(schedule_item, index_schedule) in schedule">
      <div style="border-style: none none double none; width: 100%" class="p-3 mb-4">
        <div class="d-flex justify-content-between w-100 mr-4">
          <div class="d-flex justify-content-between w-75">
            <AppInput
              type="string"
              :label="$t('tour_order_template.schedule_day') + ' ' + (index_schedule +1).toString()"
              input-style="normal"
              name="schedule[index_schedule]"
              v-model="schedule[index_schedule].title"
            />
            <AppDatePicker
              input-style="normal"
              name="schedule_date"
              :label="$t('tour_order_template.schedule_date')"
              v-model="schedule[index_schedule].date"
            />
            <AppInput
              input-style="normal"
              name="schedule_description"
              :label="$t('tour_order_template.schedule_des')"
              v-model="schedule[index_schedule].description"
              type="string"
            />
          </div>
          <button
            v-if="schedule.length > 1"
            @click="removeSchedule('schedule', index_schedule)"
            type="button"
            class="btn-tool delete mt-4"
            style="margin-right: 33px;"
          ></button>
        </div>
        <div>
          <div class="dataTables_wrapper no-footer bg-white">
            <div class="table-responsive sm-m-b-15">
              <table class="table dataTable app-table no-footer table_tab3"
                     style="border: solid 1px #e4e4e4; border-bottom:  none !important;">
                <thead>
                <tr>
                  <th class="min-w-100">{{ $t('tour_order_template.is_draft_reserved') }}</th>
                  <th class="min-w-100">{{ $t('tour_order_template.is_final_reserved') }}</th>
                  <th class="min-w-150">{{ $t('tour_order_template.reserved_content') }}</th>
                  <th class="min-w-100">{{ $t('tour_order_template.schedule_time') }}</th>
                  <th class="min-w-100">{{ $t('tour_order_template.offices_name') }}</th>
                  <th class="min-w-100">{{ $t('tour_order_template.offices_explain') }}</th>
                  <th class="min-w-150">{{ $t('tour_order_template.offices_image') }}</th>
                  <th class="min-w-100">{{ $t('tour_order_template.offices_map_url') }}</th>
                  <th class="min-w-100">{{ $t('tour_order_template.offices_tel') }}</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(scheduleDetail, index_schedule_item) in schedule_item.detail">
                  <tr>
                    <td class="text-center">
                      <input type="checkbox"
                             v-model="schedule[index_schedule].detail[index_schedule_item].is_draft_reserved"
                      />
                    </td>
                    <td class="text-center">
                      <input type="checkbox"
                             v-model="schedule[index_schedule].detail[index_schedule_item].is_final_reserved"
                      />
                    </td>
                    <td>
                      <AppText
                        type="text"
                        input-style="normal"
                        name="reserved_content"
                        v-model="schedule[index_schedule].detail[index_schedule_item].reserved_content"
                        textarea-style="height: 50px"
                        boundaryClass="m-b-0 p-0"
                      />
                    </td>
                    <td>
                      <VueTimepicker
                        input-style="normal"
                        name="time_schedule"
                        hour-label="時"
                        minute-label="分"
                        v-model="schedule[index_schedule].detail[index_schedule_item].time_schedule"
                      />
                    </td>
                    <td>
                      {{ $log(scheduleDetail.office_id, 'scheduleDetail.office_id') }}
                      <AppInput v-if="!_.find(offices, (o) => {return scheduleDetail.office_id == undefined ? false : o.id == scheduleDetail.office_id})
                                      && order_templates.booking_status == 4
                                      && scheduleDetail.office_id != undefined"
                                boundary-class="m-b-0 p-0"
                                name="office_id"
                                input-style="normal"
                                type="string"
                                :disabled="true"
                                v-model="scheduleDetail.office_name"/>
                      <AppSelect v-else
                        input-style="normal"
                        :options="{containerCssClass: 'z-index-0'}"
                        name="office_id"
                        :options-data="offices"
                        boundaryClass="m-auto"
                        v-model="scheduleDetail.office_id"
                        @change="changeOffice($event, index_schedule, index_schedule_item)"
                      />
                    </td>

                    <td>
                      <AppText
                        type="text"
                        input-style="normal"
                        name="description"
                        v-model="schedule[index_schedule].detail[index_schedule_item].description"
                        textarea-style="height: 50px"
                        boundaryClass="m-b-0 p-0"
                      />

                    </td>

                    <td>
                      <img
                        height="80px"
                        width="80px"
                        v-if="schedule[index_schedule].detail[index_schedule_item].image != '' && schedule[index_schedule].detail[index_schedule_item].image != null"
                        :src="schedule[index_schedule].detail[index_schedule_item].image"
                      />
                      <div
                        v-if="schedule[index_schedule].detail[index_schedule_item].image != '' && schedule[index_schedule].detail[index_schedule_item].image != null"
                        class="btn-tool delete table-remove-image"
                        @click="removeScheduleImage(index_schedule, index_schedule_item, 'image' + index_schedule + '-' + index_schedule_item)">
                      </div>
                      <input
                        :ref="'image' + index_schedule + '-' + index_schedule_item"
                        accept="image/jpg,image/jpeg,image/png"
                        style="height:40px;"
                        class="form-control"
                        type="file"
                        name="image"
                        @change="uploadImage($event, index_schedule, index_schedule_item)"
                      >
                    </td>

                    <td>
                      <AppInput
                        boundary-class="m-b-0 p-0"
                        input-style="normal"
                        type="url"
                        rules="url"
                        name="map_url"
                        v-model="schedule[index_schedule].detail[index_schedule_item].map_url"
                      />
                    </td>

                    <td>
                      <AppInput
                        boundary-class="m-b-0 p-0"
                        input-style="normal"
                        type="number-length:11"
                        rules="phone"
                        name="tel"
                        v-model="schedule[index_schedule].detail[index_schedule_item].phone"
                      />
                    </td>

                    <td>
                      <button
                        v-if="schedule_item.detail.length > 1"
                        type="button"
                        class="btn-tool delete"
                        @click="removeSchedule('schedule_item', index_schedule, index_schedule_item)"
                      ></button>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-12" style="margin-bottom:20px;margin-top:5px;padding-left: 0">
          <button
            @click="addSchedule('schedule_item', index_schedule)"
            type="button"
            class="btn btn-add-row"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </template>


    <div class="col-md-12 mt-3 d-flex justify-content-center w-75 pl-2" style="text-align:center;margin-bottom:20px;">
      <button
        @click="addSchedule('schedule')"
        type="button"
        class="btn btn-create"
      ><i class="fa fa-plus" aria-hidden="true"></i> スケジュールを追加
      </button>
    </div>
  </div>
</template>
<script>
import App from "@/App";

export default {
  props: ["schedule", "offices", "order_templates"],
  data() {
    return {
      delete: {
        name: "",
        key_1: "",
        key_2: "",
      },
    }
  },
  mounted() {
  },
  methods: {
    deleteEntrySubmit() {
      if (this.delete.name == 'schedule_item') {
        this.schedule[this.delete.key_1].detail.splice(this.delete.key_2, 1);
      }

      if (this.delete.name == 'schedule') {
        this.schedule.splice(this.delete.key_1, 1);
      }

      this.delete = {
        name: "",
        key_1: "",
        key_2: "",
      }

      this.$refs.modal_delete_schedule.hide();
    },
    clearEntryToSave() {
      this.delete = {
        name: "",
        key_1: "",
        key_2: "",
      }
    },
    async changeOffice(id, index1, index2) {
      let cloneSchedule = JSON.parse(localStorage.getItem('SCHEDULE'));

      try {
        cloneSchedule[index1].detail[index2].office_id;
      } catch (e) {
        const res = await this.$request.get(this.$consts.API.ADMIN.OFFICES_EDIT(id));

        if (res.data.data != undefined) {
          this.schedule[index1].detail[index2].description = res.data.data.description;
          this.schedule[index1].detail[index2].image = res.data.data.image;
          this.schedule[index1].detail[index2].map_url = res.data.data.map_url;
          this.schedule[index1].detail[index2].phone = res.data.data.tel;
        }
        return;
      }

      if (id != null) {
        if (id != cloneSchedule[index1].detail[index2].office_id || !this.order_templates.id) {
          const res = await this.$request.get(this.$consts.API.ADMIN.OFFICES_EDIT(id));

          if (res.data.data != undefined) {
            this.schedule[index1].detail[index2].description = res.data.data.description;
            this.schedule[index1].detail[index2].image = res.data.data.image;
            this.schedule[index1].detail[index2].map_url = res.data.data.map_url;
            this.schedule[index1].detail[index2].phone = res.data.data.tel;
          }
        } else {
          this.schedule[index1].detail[index2].description = cloneSchedule[index1].detail[index2].description;
          this.schedule[index1].detail[index2].image = cloneSchedule[index1].detail[index2].image;
          this.schedule[index1].detail[index2].map_url = cloneSchedule[index1].detail[index2].map_url;
          this.schedule[index1].detail[index2].phone = cloneSchedule[index1].detail[index2].phone;
        }
      }
    },
    addSchedule(type, index) {
      let cloneScheduleDetail = {
        time_schedule: "",
        office_id: null,
        office_name: "",
        description: "",
        image: "",
        map_url: "",
        phone: "",
      };

      if (type == "schedule") {
        let day = Number(this.schedule.length) + 1;
        this.schedule.push({
          date: "",
          description: "",
          detail: [
            cloneScheduleDetail
          ]
        });
      }

      if (type == "schedule_item") {
        this.schedule[index].detail.push(cloneScheduleDetail);
      }
    },
    removeSchedule(type, index1, index2) {
      this.$refs.modal_delete_schedule.show();
      this.delete = {
        name: type,
        key_1: index1,
        key_2: index2,
      }
    },
    async uploadImage(event, index1, index2) {
      const image = event.target.files[0];

      if (this.checkImage(image)) {
        this.$appNotice.error(this.$t('dialog.image_type'));

        return;
      }

      if ((image.size / 1048576) > 2) {
        this.$appNotice.error(this.$t('dialog.image_max', {size: '2MB'}));

        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onload = e => {
        this.uploadImage1(e.target.result, index1, index2)
      };
    },
    async uploadImage1(file, index1, index2) {
      let path = "tours/schedules";

      const res = await this.$request.post(this.$consts.API.ADMIN.UPLOAD_IMAGE, {
        image: file,
        path: path,
      });

      this.schedule[index1].detail[index2].image = res.data.data;
    },
    removeScheduleImage(index_schedule, index_schedule_item, input)
    {
      this.schedule[index_schedule].detail[index_schedule_item].image = '';
      this.$refs[input].value = null
    }
  }
}
</script>

<style scoped>
table.table.dataTable.app-table.no-footer td {
  vertical-align: middle;
}

table.table.dataTable.app-table.no-footer td .form-group {
  margin-bottom: 0 !important;
}

table.table.dataTable.app-table.no-footer input,
table.table.dataTable.app-table.no-footer select,
.select2-container .select2-selection--single {
  min-height: 40px !important;
  height: 40px !important;
}

table.table.dataTable.app-table.no-footer td:nth-of-type(2) {
  width: 15%;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 35px !important;
}

.select2-container .select2-selection .select2-selection__arrow {
  bottom: 0 !important;
  height: 40px;
}

.vue__time-picker .controls > * {
  height: 40px !important;
  min-height: 40px;
}

.table_tab3 tbody td:last-child {
  width: 45px;
}
</style>
