<template>
  <div class="row">
    <ConfirmModal
        ref="modal_delete_budget"
        :modal-header-title="$t('common.delete confirm title')"
        :confirm-handle="deleteEntrySubmit"
        :on-hide="clearEntryToSave"
      >
    </ConfirmModal>

    <template v-for="(buget_item, index_buget) in buget">
      <div class="mb-3 w-100 pt-3 pb-3 pl-3 pr-3" style=" border-style: none none double none;">
        <div class="d-flex justify-content-between w-100">
        <AppInput
          type="string"
          input-style="normal"
          name="buget_key"
          v-model="buget[index_buget].key"
        />
        <button
          v-if="buget.length > 1"
          class="btn-tool delete mt-2"
          style="margin-right: 70px; !important;"
          @click="removeBudget('budget', index_buget, null, $event)"
        ></button>
      </div>
        <div class="w-100 pr-3">
          <table class="table dataTable app-table no-footer table_price" style="border: solid 1px #ccc">
            <thead>
              <tr>
                <th>{{ $t('tour_order_template.budgets_name') }}</th>
                <th>{{ $t('tour_order_template.budgets_quantity') }}</th>
                <th>{{ $t('tour_order_template.budgets_price') }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(buget_item_item, index_buget_item) in buget_item.value">
                <tr>
                  <td>
                    <AppInput
                      input-style="normal"
                      name="buget_item_name"
                      type="string"
                      boundaryClass="m-b-0"
                      v-model="buget[index_buget].value[index_buget_item].name"
                    />
                  </td>

                  <td style="width: 15%;">
                    <AppInput
                      rules="min_value:1"
                      type="bigint"
                      :name="`buget_item_quantity_${index_buget}_${index_buget_item}`"
                      input-style="normal"
                      boundaryClass="m-b-0"
                      v-model="buget_item.value[index_buget_item].quantity"
                    />
                  </td>

                  <td style="width: 15%;">
                    <div class="d-flex">
                      <AppInput
                        rules="min_value:1"
                        type="bigint"
                        :name="`buget_item_price_${index_buget}_${index_buget_item}`"
                        input-style="normal"
                        v-model="buget[index_buget].value[index_buget_item].price"
                        boundaryClass="m-b-0"
                        :change="totalPrice(index_buget, index_buget_item)"
                      />
                      <span style="margin-left:10px;font-size:17px;font-weight:bold;" class="align-self-center align-items-center">円</span>
                    </div>

                  </td>

                  <td>
                    <button
                      v-if="buget_item.value.length > 1"
                      class="btn-tool delete" style="margin: auto;display: block"
                      @click="removeBudget('buget_item', index_buget, index_buget_item, $event)"
                    ></button>
                  </td>
                </tr>
              </template>
            </tbody>
        </table>
        </div>
        <div class="col-md-12 pl-0" style="margin-top:5px;">
          <button
            @click="addBudget('budgetItem', index_buget)"
            type="button"
            class="btn btn-add-row"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </template>

    <div class="col-md-12 pl-3" style="text-align:center;margin-bottom:20px;">
      <button
        @click="addBudget('budget')"
        type="button"
        class="btn btn-create"
      >
        <i class="fa fa-plus" aria-hidden="true">予算を追加する</i>
      </button>
    </div>

    <div class="col-md-5 ml-4">
      <label
        style="padding:10px;width:100%;text-align:center;background:#015cac;color: #fff;font-weight: bold"
      >
        {{ $t('tour_order_template.budgets_total') }}
    </label>
    </div>

    <div class="col-md-4 d-flex offset-md-2 pr-5 justify-content-end">
      <label
        style="padding:10px;width:50%;text-align:center;background:#015cac;color:#fff;font-weight: bold;"
      >
        {{ formatPrice(order_templates.price) }}円
    </label>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["buget", "order_templates"],
    data() {
      return {
        delete: {
          name: "",
          key_1: "",
          key_2: "",
        }
      }
    },
    mounted() {
      this.totalPrice();
    },
    methods: {
      deleteEntrySubmit()
      {
        if (this.delete.name == "buget_item") {
          this.buget[this.delete.key_1].value.splice(this.delete.key_2, 1);
        }

        if (this.delete.name == "budget") {
          this.buget.splice(this.delete.key_1, 1);
        }

        this.delete = {
          name: "",
          key_1: "",
          key_2: "",
        }

        this.$refs.modal_delete_budget.hide();
        this.totalPrice();
      },
      clearEntryToSave(){
        this.delete = {
          name: "",
          key_1: "",
          key_2: "",
        }
      },
      formatPrice(value) {
        if (value != undefined) {
          return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        }
      },
      totalPrice(index1, index2)
      {
        if (index1 != undefined && index2 != undefined) {
          this.buget[index1].value[index2].price = this.buget[index1].value[index2].price;
        }

        let total = 0;

        this.buget.filter(function(bugets, index_1) {
          bugets.value.filter(function(buget) {
            total = Number(buget.price) + total;
          });
        });

        if (this.order_templates.price != undefined) {
          this.order_templates.price = total;
        }
      },
      addBudget(type, index)
      {
        if (type == 'budget') {
          this.buget.push({
            key: "",
            value: [
              {
                name: "",
                quantity: "",
                price: "",
              }
            ]
          });
        }
        if (type == "budgetItem")
        {
          this.buget[index].value.push(
            {
              name: "",
              quantity: "",
              price: "",
            }
          );
        }
      },
      removeBudget(type, index1, index2, event)
      {
        if (event) {
          event.preventDefault()
        }

        this.delete = {
          name: type,
          key_1: index1,
          key_2: index2,
        }

        this.$refs.modal_delete_budget.show();
      },
    }
  }
</script>
<style scoped>
.table_price td:last-child {
  width: 10% !important;
}
@media (max-width: 1500px) {
  .tab-pane .d-flex.justify-content-between.w-100 .btn-tool.delete {
    margin-right: 50px !important;
  }
}

@media screen and (min-width: 1500px) {
  .tab-pane .table_datetime td:last-child {
    width: 5% !important;
  }
}
.table_datetime td:nth-of-type(1) {
  width: 20%;
}
.table_datetime td:nth-of-type(2) {
  width: 30% !important;
}
.table_datetime td:last-child {
  width: 6% !important;
}
table.table.dataTable.app-table.no-footer td {
  vertical-align: middle;
}
table.table.dataTable.app-table.no-footer td .form-group {
  margin-bottom: 0 !important;
}
table.table.dataTable.app-table.no-footer input,
table.table.dataTable.app-table.no-footer select,
table.table.dataTable.app-table.no-footer .vue__time-picker,
.select2-container .select2-selection--single {
  min-height: 40px !important;
  height: 40px !important;
}

table.table.dataTable.app-table.no-footer .vue__time-picker input {
  width: 100% !important;
}
table.table.dataTable.app-table.no-footer td:nth-of-type(2) {
  width: 15%;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 35px !important;
}
.select2-container .select2-selection .select2-selection__arrow {
  bottom: 0 !important;
  height: 40px;
}
.vue__time-picker .controls > * {
  height: 40px !important;
  min-height: 40px;
}
.vue__time-picker {
  width: 100% !important;
}
</style>

