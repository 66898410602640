<template>
  <div class="container-fluid container-fixed-lg bg-white app-table-container">
    <ConfirmModal
      ref="modal_delete_entry"
      :modal-header-title="$t('common.delete confirm title')"
      :confirm-handle="deleteEntrySubmit"
      :on-hide="clearEntryToSave"
    >
    </ConfirmModal>
    <ConfirmModal
      ref="modal_confirm_cancel"
      :modal-header-title="$t('common.confirm_cancel_title')"
      :confirm-handle="redirectBack">
    </ConfirmModal>
    <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
      <form novalidate autocomplete="off" v-on:submit.prevent="passes(saveOrderTemplate)">
        <div class="m-t-10 row justify-content-end">
          <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="confirmCancel">
            {{ $t('common.cancel') }}
          </button>
          <button :disabled="!valid && validated" class="btn btn-complete m-r-10" type="submit">
            {{ order_templates.id != '' ? $t('common.update_btn') : $t('common.create_btn') }}
          </button>
        </div>
        <div class="row" style="margin-top:20px;">
        <div class="col-md-12" style="margin-bottom:10px">
          <label class="control-label input-label p-0" style="margin-left:10px;">
            {{ $t('tour_order_template.area_label') }}
          </label>
        </div>
        <div class="w-100 pb-2">
          <div class="mr-2 ml-2">
            <table class="table dataTable app-table no-footer">
            <thead>
              <tr>
                <th>{{ $t('tour_order_template.area_prefecture') }}</th>
                <th>{{ $t('tour_order_template.area_city') }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(area, index_area) in areas">
                <tr>
                  <td style="width: 45%">
                    <AppSelect
                      :onChange="(id) => changePrefecture(id, index_area)"
                      input-style="normal"
                      name="prefecture_id"
                      boundaryClass="m-auto"
                      :options="{containerCssClass: 'z-index-0'}"
                      :options-data="options_prefecture"
                      :value="areas[index_area].prefecture_id"
                      v-model="areas[index_area].prefecture_id"
                    />
                  </td>
                  <td style="width: 45%">
                    <AppSelect
                      :options="{containerCssClass: 'z-index-0'}"
                      :onChange="(id) => changeCity(id, index_area)"
                      input-style="normal"
                      name="city_id"
                      boundaryClass="m-auto"
                      :options-data="area.cities"
                      :value="areas[index_area].city_id"
                      v-model="areas[index_area].city_id"
                    />
                  </td>
                  <td style="width: 10%">
                    <div v-if="areas.length > 1" class="btn-tool delete" @click="removeArea(index_area)" style="margin: auto; margin-top: 5px"></div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          </div>
          <div class="m-t-5 ml-2 row w-100">
            <button
              @click="addArea()"
              type="button"
              class="btn btn-add-row"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
        </div>
        </div>

        <div class="col-md-12 mt-4">
          <AppInput
            input-style="normal"
            name="name"
            :label="$t('tour_order_template.name')"
            type="string"
            rules="required"
            v-model="order_templates.name"
          />
        </div>

        <div class="col-md-12">
          <AppInput
            type="url"
            name="public_url"
            :label="$t('tour_order_template.public_url')"
            input-style="normal"
            :disabled="true"
            v-model="order_templates.public_url"
          />
        </div>

        <div class="col-md-12">
          <AppText
            type="text"
            input-style="normal"
            name="edit_history"
            :label="$t('tour_order_template.edit_history')"
            v-model="order_templates.edit_history"
          />
        </div>

        <div class="col-md-12">
          <AppText
            type="text"
            input-style="normal"
            name="description"
            :label="$t('tour_order_template.description')"
            v-model="order_templates.description"
          />
        </div>
        <div class=" w-100">
          <div class="col-md-12 pl-2" style="margin-top:30px;">
          <ul class="nav nav-tabs nav-tabs-fillup d-none d-md-flex d-lg-flex d-xl-flex"  data-init-reponsive-tabs="dropdownfx">
            <li class="nav-item">
              <a class="active show" data-toggle="tab" href="#tabs-1" role="tab">
                <span>{{ $t('tour_order_template.tourname_name') }}</span>
              </a>
            </li>

            <li class="nav-item">
              <a data-toggle="tab" href="#tabs-2" role="tab">
                <span>{{ $t('tour_order_template.tourname_concept') }}</span>
              </a>
            </li>

            <li class="nav-item">
              <a data-toggle="tab" href="#tabs-3" role="tab">
                <span>{{ $t('tour_order_template.schedule_templates') }}</span>
              </a>
            </li>

            <li class="nav-item">
              <a  data-toggle="tab" href="#tabs-4" role="tab">
                <span>{{ $t('tour_order_template.budgets') }}</span>
              </a>
            </li>

            <li class="nav-item">
              <a  data-toggle="tab" href="#tabs-5" role="tab">
                <span>{{ $t('tour_order_template.note') }}</span>
              </a>
            </li>

            <li class="nav-item">
              <a  data-toggle="tab" href="#tabs-6" role="tab">
                <span>{{ $t('tour_order_template.contact') }}</span>
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane active" id="tabs-1" role="tabpanel">
              <AppInput
                type="string"
                name="tourname_name"
                :label="$t('tour_order_template.tourname_name_name')"
                input-style="normal"
                v-model="order_templates.tourname_name"
              />

              <AppText
                type="text"
                name="tourname_explain"
                :label="$t('tour_order_template.tourname_name_explain')"
                input-style="normal"
                v-model="order_templates.tourname_explain"
              />

              <div class="form-group">
                <div class="form-group">
                  <label class="input-label control-label p-0 p-r-5">
                    {{ $t('tour_order_template.tourname_image') }}
                  </label>
                </div>

                <div class="form-group">
                  <img
                    :height="tourNameImage.height + 'px'"
                    :width="tourNameImage.width + 'px'"
                    v-if="order_templates.tourname_image_preview != ''"
                    :src="order_templates.tourname_image_preview"
                  />
                  <div
                    v-if="order_templates.tourname_image_preview != ''"
                    class="btn-tool delete remove-image"
                    @click="removeImage()">
                  </div>

                  <input
                    class="form-control"
                    accept="image/jpg,image/jpeg,image/png"
                    type="file"
                    id="tourname_image"
                    ref="tourname_image"
                    @change="uploadImage($event, 'tourname_image')"
                  />
                </div>
              </div>
            </div>

            <div class="tab-pane" id="tabs-2" role="tabpanel">
              <div class="row">
                <table class="table dataTable app-table no-footer" style="border: solid 1px #ccc;">
                  <tbody>
                  <template v-for="(concept, index_concept) in concepts">
                    <tr>
                      <td style="width: 30%">
                        <AppInput
                          input-style="normal"
                          boundary-class="m-b-0 p-b-0"
                          type="string"
                          name="concepts_key"
                          v-model="concepts[index_concept].key"
                        />
                      </td>
                      <td style="width: 60%">
                        <AppText
                          textarea-style="height: 50px"
                          boundary-class="m-b-0 p-b-0"
                          input-style="normal"
                          type="text"
                          name="concepts_value"
                          v-model="concepts[index_concept].value"
                        ></AppText>
                      </td>
                      <td style="width: 10%; text-align: center;">
                        <button
                          style=" margin-top: 5px"
                          type="button"
                          v-if="concepts.length > 1"
                          class="btn-tool delete"
                          @click="removeConcept('concepts', index_concept)">
                        </button>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>

                <div class="col-md-12 pl-0" style="margin-top: 5px;">
                  <button
                    @click="addConcept('concepts')"
                    type="button"
                    class="btn btn-add-row"
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="tab-pane" id="tabs-3" role="tabpanel">
              <Schedule
                :schedule="schedule"
                :offices="offices"
                :validate="validate"
                :order_templates="order_templates"
              />
            </div>

            <div class="tab-pane" id="tabs-4" role="tabpanel">
              <Budget
                :buget="buget"
                :order_templates="order_templates"
              />
            </div>

            <div class="tab-pane" id="tabs-5" role="tabpanel">
              <div class="row">
                <table class="table dataTable app-table no-footer" style="border: solid 1px #ccc;">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(note_item, index_note) in note">
                      <tr>
                        <td style="width: 20%">
                          <AppInput
                            input-style="normal"
                            type="string"
                            boundary-class="m-b-0 p-b-0"
                            name="concepts_key"
                            v-model="note[index_note].key"
                          />
                        </td>

                        <td style="width: 74%">
                          <AppText
                          textarea-style="height: 50px"
                            input-style="normal"
                            boundary-class="m-b-0 p-b-0"
                            type="text"
                            name="concepts_value"
                            v-model="note[index_note].value"
                          ></AppText>
                        </td>

                        <td style="width: 6%; text-align: center; vertical-align: center">
                          <button
                            type="button"
                            style="margin-top: 5px;"
                            v-if="note.length > 1"
                            class="btn-tool delete"
                            @click="removeConcept('note', index_note)">
                          </button>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>

                <div class="col-md-12 pl-0" style="margin-top:5px;">
                  <button
                    @click="addConcept('note')"
                    type="button"
                    class="btn btn-add-row"
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="tab-pane row" id="tabs-6" role="tabpanel">
              <AppText
                type="text"
                name="contact_content"
                :label="$t('tour_order_template.contact_content')"
                input-style="normal"
                v-model="order_templates.contact_content"
              />

              <div class="form-group">
                <div class="form-group">
                  <label class="input-label control-label p-0 p-r-5">
                    {{ $t('tour_order_template.contact_image') }}
                  </label>
                </div>

                <div class="form-group">
                  <img
                    :height="contactImage.height + 'px'"
                    :width="contactImage.width + 'px'"
                    v-if="order_templates.contact_image_preview != ''"
                    :src="order_templates.contact_image_preview"
                  />
                  <div
                    v-if="order_templates.contact_image_preview != ''"
                    class="btn-tool delete remove-image"
                    @click="removeContactImage()">
                  </div>

                  <input
                    class="form-control"
                    accept="image/jpg,image/jpeg,image/png"
                    type="file"
                    id="contact_image"
                    ref="contact_image"
                    @change="uploadImage($event, 'contact_image')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
        <div class="m-t-10 row justify-content-end">
          <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="confirmCancel">
            {{ $t('common.cancel') }}
          </button>
          <button :disabled="!valid && validated" class="btn btn-complete m-r-10" type="submit">
            {{ order_templates.id != '' ? $t('common.update_btn') : $t('common.create_btn') }}
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import Schedule from "@components/admin/bookings/tour_order/Schedule";
  import Budget from "@components/admin/bookings/tour_order/Budget";
  import ROUTES from "@consts/routes";
  import App from "@/App";

  export default {
    components: {
      Schedule,
      Budget
    },
    data() {
      const image_default= {
        height:300,
        width:400
      }
      const image_default_square= {
        height:550,
        width:550
      }
      return {
        order_templates: {
          id: "",
          name: "",
          area_id: "",
          city_id: [],
          prefecture_id: [],
          price: 0,
          edit_history: "",
          description: "",
          public_url: "",
          tourname_name: "",
          tourname_explain: "",
          tourname_image: "",
          tourname_image_preview: "",
          tourname_image_thumbnail: "",
          contact_content: "",
          contact_image: "",
          contact_image_preview: "",
          contact_image_thumbnail: "",
        },
        concepts: [
          {
            key: "旅の目的",
            value: '',
          },
          {
            key: "主な内容",
            value: '',
          },
          {
            key: "楽しみ方",
            value: '',
          },
          {
            key: "ご要望など",
            value: '',
          },
        ],
        note: [
          {
            key: "服装",
            value: '',
          },
          {
            key: "Wi-Fi",
            value: '',
          },
          {
            key: "お申し込み",
            value: '',
          },
          {
            key: "手数料",
            value: '',
          },
        ],
        buget: [
          {
            key: "交通",
            value: [
              {
                name: "",
                quantity: "",
                price: "",
              }
            ],
          },
          {
            key: "宿泊",
            value: [
              {
                name: "",
                quantity: "",
                price: "",
              }
            ],
          },
          {
            key: "飲食",
            value: [
              {
                name: "",
                quantity: "",
                price: "",
              }
            ],
          },
          {
            key: "ガイド",
            value: [
              {
                name: "",
                quantity: "",
                price: "",
              }
            ],
          },
          {
            key: "手数料",
            value: [
              {
                name: "",
                quantity: "",
                price: "",
              }
            ],
          }
        ],
        schedule: [
          {
            title: "",
            date: "",
            description: "",
            detail: [
              {
                time_schedule: "",
                office_id: 0,
                plan_id: 0,
                office_name: "",
                plan_name: "",
                description: "",
                image: "",
                map_url: "",
                phone: "",
              }
            ]
          }
        ],
        cloneSchedule: [],
        options_prefecture: [],
        offices: [],
        areas: [
          {
            order_no: 1,
            prefecture_id: 0,
            city_id: 0,
            cities: [],
          }
        ],
        delete: {
          name: "",
          key_1: "",
          key_2: ""
        },
        validate:"",
        submit: true,
        freezeObj: {},
        firstLoading: true,
        image_default: _.cloneDeep(image_default),
        image_default_square: _.cloneDeep(image_default_square),
        image:{
          height:'',
          width:''
        },
        tourNameImage: _.cloneDeep(image_default),
        contactImage: _.cloneDeep(image_default),
        scheduleImage: _.cloneDeep(image_default),
      }
    },
    mounted()
    {
      let params = this.$route.params;
      this.getAlias();
      this.getOfficeData();

      if (params.id) {
        this.order_templates = params.id;
        this.getTourDetail(params.id);
      }
    },
    methods: {
      async getOfficeData()
      {
        let params = {};
        params[`filters[status][equal]`] = this.$consts.INPUT.ACTIVE;
        const res = await this.$request.get(this.$consts.API.ADMIN.OFFICES_LIST_NAME, params);

        if (res.data.data != undefined) {
          this.offices = res.data.data;
        }
      },
      deleteEntrySubmit()
      {
        if (this.delete.name == "areas") {
          this.areas.splice(this.delete.key_1, 1);
        }

        if (this.delete.name == "concepts") {
          this.concepts.splice(this.delete.key_1, 1);
        }

        if (this.delete.name == "note") {
          this.note.splice(this.delete.key_1, 1);
        }

        this.$refs.modal_delete_entry.hide();
      },
      clearEntryToSave() {
        this.delete = {
          name: "",
          key_1: "",
          key_2: "",
        }
      },
      removeArea(index)
      {
        this.$refs.modal_delete_entry.show();

        this.delete = {
          name: "areas",
          key_1: index,
          key_2: "",
        }
      },
      addArea()
      {
        this.areas.push({
          order_no: this.areas.length,
          prefecture_id: 0,
          city_id:0,
          cities: [{
            id: 0,
            name: this.$t('common.no_select_all'),
          }],
        });
      },
      async getTourDetail(id)
      {
        const res = await this.$request.get(this.$consts.API.ADMIN.B_TOUR_ORDER_EDIT(id));

        if (!res.hasErrors()) {
          let order_templates = res.data.data;
          let prefectures = this.prefectures = order_templates.prefectures;
          let cities = this.cities = order_templates.cities;
          let prefecture_id = [];
          let city_id = [];
          let new_areas = [];
          let listPrefecture = [];
          cities.forEach(city => {
            listPrefecture.push(city.prefecture_id)
          })
          let params = {};
          params[`filters[prefecture_id]`] = listPrefecture.join(',') ;
          let listPre = await this.$request.get(this.$consts.API.ADMIN.TOUR_PREFECTURES_CITIES, params);

          for (var i = 0; i < cities.length; i++) {
            let newCites = []
            listPre.data.data.forEach(pre => {
              if(pre.id === cities[i].prefecture_id) newCites = pre.cities
            })
            newCites.push({
              id: 0,
              name: this.$t('common.no_select_all'),
            });
            if (!res.hasErrors()) {
              new_areas.push({
                order_no: Number(i) + 1,
                prefecture_id: cities[i].prefecture_id,
                city_id: cities[i].id,
                cities: newCites,
              });
            }
          }

          if (new_areas.length > 0) {
            this.areas = new_areas;
          }

          this.order_templates =  {
            id: order_templates.id,
            name: order_templates.name,
            price: order_templates.price,
            edit_history: order_templates.edit_history,
            description: order_templates.description,
            public_url: order_templates.public_url,
            tourname_name: order_templates.tourname_name,
            tourname_explain: order_templates.tourname_explain,
            tourname_image: order_templates.tourname_image != null ? order_templates.tourname_image : '',
            tourname_image_preview: order_templates.tourname_image_preview != null ? order_templates.tourname_image_preview : '',
            tourname_image_thumbnail: order_templates.tourname_image_thumbnail != null ? order_templates.tourname_image_thumbnail : '',
            contact_content: order_templates.contact_content,
            contact_image: order_templates.contact_image != null ? order_templates.contact_image : '',
            contact_image_preview: order_templates.contact_image_preview != null ? order_templates.contact_image_preview : '',
            contact_image_thumbnail: order_templates.contact_image_thumbnail != null ? order_templates.contact_image_thumbnail : '',
            prefecture_id: prefecture_id,
            city_id: city_id,
            booking_id: order_templates.booking_id,
            booking_status: order_templates.booking_status,
          }

          this.concepts = order_templates.concept;
          this.note = order_templates.note;
          this.buget = order_templates.budget;
          localStorage.setItem('SCHEDULE', JSON.stringify(order_templates.schedules));
          this.cloneSchedule = order_templates.schedules;
          this.schedule = order_templates.schedules;
          var formatSchedules = _.cloneDeep(this.schedule)
          _.forEach(formatSchedules, (schedule, index) => {
            let formatDate = schedule.date ? moment(schedule.date).format('YYYY/MM/DD') : "";
            formatSchedules[index].date = formatDate;
          });
          this.schedule = _.cloneDeep(formatSchedules);
          this.freezeObj = _.cloneDeep({
            order_templates: this.order_templates,
            concepts: this.concepts,
            note: this.note,
            buget: this.buget,
            areas: this.areas,
            schedule: this.schedule,
          });
        } else {
          this.$appNotice.error(res.data?.error_msg)
          this.$router.push({name: ROUTES.ADMIN.BOOKINGS});
        }
      },
      async changePrefecture(id, index)
      {
        this.areas[index].cities = [];

        if (id != null && id != 0) {
          let params = {
            sortType: "asc",
            sortBy: "code",
          };
          params[`filters[prefecture_id][equal]`] = id;
          const res = await this.$request.get(this.$consts.API.ADMIN.TOUR_CITIES, params);
          this.areas[index].prefecture_id = id;
          this.areas[index].cities = [];

          if (!res.hasErrors()) {
            this.areas[index].cities = res.data.data;

            if (this.areas[index].cities.length == 0) {
              this.areas[index].cities.push({
                id: 0,
                name: this.$t('common.no_select_all'),
              });
            }

            this.areas[index].city_id = res.data.data[0].id;
          } else {
            this.$appNotice.error(res.data?.error_msg)
          }
        } else if (id == 0) {
          this.areas[index].cities.push({
            id: 0,
            name: this.$t('common.no_select_all'),
          });

          this.areas[index].city_id = 0;
        }
      },
      changeCity(id, index) {
        this.areas[index].city_id = id;
        if (this.firstLoading) {
          this.freezeObj = _.cloneDeep({
            order_templates: this.order_templates,
            concepts: this.concepts,
            note: this.note,
            buget: this.buget,
            areas: this.areas,
            schedule: this.schedule,
          });
          this.firstLoading = false;
        }
      },
      addConcept(type)
      {
        if (type == "concepts") {
          this.concepts.push({
            key: '',
            value: '',
          });
        }

        if (type == "note") {
          this.note.push({
            key: '',
            value: '',
          });
        }
      },
      removeConcept(type, index_item)
      {
        this.$refs.modal_delete_entry.show();

        this.delete = {
          name: type,
          key_1: index_item,
          key_2: "",
        }
      },
      async getAlias()
      {
        const res = await this.$request.get(this.$consts.API.ADMIN.TOUR_PREFECTURES, {
          sortType: "asc",
          sortBy: "code",
        });

        if (!res.hasErrors()) {
          this.options_prefecture = res.data.data;
          this.options_prefecture.push({
            id: 0,
            name: this.$t('common.no_select_all'),
          });
        } else {
         this.$appNotice.error(res.data?.error_msg)
        }
      },
      async saveOrderTemplate()
      {
        this.submit= true;
        const areas = [];
        let city_id = this.order_templates.city_id;

        this.areas.filter(function(area){
          if (area.prefecture_id != 0) {
            areas.push({
              order_no: area.order_no,
              prefecture_id: Number(area.prefecture_id),
              city_id: Number(area.city_id),
            });
          }
        });

        if (!this.submit) {
          return false;
        }

        let orderTourTemplateData = {
          name: this.order_templates.name,
          prefecture_id: this.order_templates.prefecture_id,
          edit_history: this.order_templates.edit_history,
          city_id: this.order_templates.city_id,
          price: this.order_templates.price,
          description: this.order_templates.description,
          public_url: this.order_templates.public_url,
          tourname_name: this.order_templates.tourname_name,
          tourname_explain: this.order_templates.tourname_explain,
          tourname_image: this.order_templates.tourname_image,
          tourname_image_preview: this.order_templates.tourname_image_preview,
          tourname_image_thumbnail: this.order_templates.tourname_image_thumbnail,
          concept: this.concepts,
          note: this.note,
          schedules: this.schedule,
          budget: this.buget,
          contact_content: this.order_templates.contact_content,
          contact_image: this.order_templates.contact_image,
          contact_image_preview: this.order_templates.contact_image_preview,
          contact_image_thumbnail: this.order_templates.contact_image_thumbnail,
          areas: areas,
        };
        const res = await this.$request.patch(this.$consts.API.ADMIN.B_TOUR_ORDER_EDIT(this.order_templates.id), orderTourTemplateData);
        if (res.hasErrors()) {
          this.$appNotice.error( res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        } else {
          this.$appNotice.success(this.$t('common.msg update ok'));
          this.redirectBack()
        }
      },
      async uploadImage(event, type, index1, index2)
      {
        const image = event.target.files[0];

        if (this.checkImage(image)) {
          this.$appNotice.error(this.$t('dialog.image_type'));

          return;
        }

        if ((image.size / 1048576) > 5) {
          this.$appNotice.error(this.$t('dialog.image_max', {size: '5MB'}));

          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = e =>{
          this.uploadImage1(e.target.result, type, index1, index2, image.size)
          let img = new Image();
          img.onload = () => {
            this.image.width = img.width;
            this.image.height = img.height;
            this.changeSize(img.width, img.height, type)
          }
          img.src = e.target.result;
        };
      },
      async changeSize(width, height, type) {
        if (type == 'tourname_image') {
          this.tourNameImage = _.cloneDeep(this.image_default)
          if (width === height) {
            this.tourNameImage = _.cloneDeep(this.image_default_square)
          }
        }

        if (type == 'contact_image') {
          this.contactImage = _.cloneDeep(this.image_default)
          if (width === height) {
            this.contactImage = _.cloneDeep(this.image_default_square)
          }
        }

        if (type == 'schedules_image') {
          this.scheduleImage = _.cloneDeep(this.image_default)
          if (width === height) {
            this.scheduleImage = _.cloneDeep(this.image_default_square)
          }
        }
      },
      async uploadImage1(file, type, index1, index2, size)
      {
        let path="";

        if (type == "tourname_image") {
          path = "tours/names";
        }

        if (type == "contact_image") {
          path = "tours/contacts";
        }

        if (type == "schedules_image") {
          path = "tours/schedules";
        }

        const res = await this.$request.post(this.$consts.API.ADMIN.UPLOAD_IMAGE, {
          image: file,
          size: size,
          path: path,
        });

        if (type == "tourname_image") {
          this.order_templates.tourname_image = res.data.data;
          this.order_templates.tourname_image_preview = res.data.extra.preview;
          this.order_templates.tourname_image_thumbnail = res.data.extra.thumbnail;
        }

        if (type == "contact_image") {
          this.order_templates.contact_image = res.data.data;
          this.order_templates.contact_image_preview = res.data.extra.preview;
          this.order_templates.contact_image_thumbnail = res.data.extra.thumbnail;
        }
      },
      confirmCancel()
      {
        this.$refs.modal_confirm_cancel.show();
        // let currentObj = {
        //   order_templates: this.order_templates,
        //   concepts: this.concepts,
        //   note: this.note,
        //   buget: this.buget,
        //   areas: this.areas,
        //   schedule: this.schedule
        // };
        //
        // if (!_.isEqual(this.freezeObj, currentObj)) {
        //   this.$refs.modal_confirm_cancel.show();
        // } else {
        //   this.redirectBack();
        // }
      },
      redirectBack() {
        if (this.$store.state.setting.prevRouter.name === this.$consts.ROUTES.ADMIN.BOOKINGS) {
          this.$router.go(-1);
        } else {
          this.$router.pushByName(this.$consts.ROUTES.ADMIN.BOOKINGS);
        }
      },

      removeImage(){
        this.order_templates.tourname_image = '';
        this.order_templates.tourname_image_preview = '';
        this.order_templates.tourname_image_thumbnail = '';
        this.$refs.tourname_image.value = null
      },
      removeContactImage(){
        this.order_templates.contact_image = '';
        this.order_templates.contact_image_preview = '';
        this.order_templates.contact_image_thumbnail = '';
        this.$refs.contact_image.value = null
      }
    }
  }
</script>

<style scoped>
  .modal-confirm {
    z-index: 9999 !important;
  }
  .tab-content {
    border: 1px solid grey;
    border-top: 0;
  }
</style>
